import haLocales from '@ha/components-v3/src/locales/index.js'
import locales from "./src/locales";

const i18nOptions = {
  fallbackLocale: 'fr',
  locale: 'fr',
  globalInjection: true,
  messages: { fr: {}, en: {} },
  legacy: false
}
if (locales?.fr) i18nOptions.messages.fr = { ...haLocales.fr, ...locales.fr }
if (locales?.en) i18nOptions.messages.en = { ...haLocales.en, ...locales.en }

export default defineI18nConfig(() => i18nOptions)
