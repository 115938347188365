import { Tabs, DateOptions, Filters } from '@/helpers'
import { TABS } from '~/domains/search/interfaces/filters.interface'

export default {
  accessibility: {
    openNewWindow: 'Ouvrir dans une nouvelle fenêtre'
  },
  adminSettings: {
    admin: 'Administrer mon organisme',
    adminShort: 'Administration',
    seeEditMode: 'Passer en mode Édition',
    seeEditModeShort: 'Mode Édition',
    seePublicPage: 'Voir la version publique',
    seePublicPageShort: 'Version publique',
    share: 'Partager ma page publique',
    shareShort: 'Partager la page',
    submenu: {
      backLabel: 'Retour',
      networks: {
        copyUrl: {
          label: "Copier l'URL de la page",
          labelShort: "Copier l'URL"
        },
        email: {
          body: 'Bonjour ! Je vous partage la page de cet organisme que j’ai découvert sur Helloasso. Je pense que ses activités pourraient vous intéresser ! Rendez-vous sur la page {0} pour plus d’infos. À bientôt.',
          label: 'Partager par email',
          labelShort: 'Email',
          subject: 'Découvre l’organisme {0}.'
        },
        facebook: {
          label: 'Partager sur Facebook',
          labelShort: 'Facebook'
        },
        linkedin: {
          label: 'Partager sur LinkedIn',
          labelShort: 'LinkedIn'
        },
        messenger: {
          labelShort: 'Messenger'
        },
        twitter: {
          label: 'Partager sur Twitter',
          labelShort: 'Twitter',
          text: "J'ai découvert l'organisme {0} sur HelloAsso, ça pourrait vous intéresser ! {1} {'@'}helloasso"
        },
        whatsapp: {
          label: 'Partager via WhatsApp',
          labelShort: 'WhatsApp',
          text: "J'ai découvert l'organisme {0} sur HelloAsso, ça pourrait vous intéresser ! {1} {'@'}helloasso"
        }
      }
    }
  },
  amountCard: {
    already: 'déjà',
    collectedBy: 'collectés par',
    organizations: 'associations',
    thanksToYourSupport: 'grâce à votre soutien'
  },
  banner: {
    campaignNotFound: {
      hideBanner: 'Cacher le bandeau',
      title:
        "La page demandée n'est pas accessible, mais celle ci peut vous intéresser !"
    },
    editMode: {
      cta: {
        edit: 'Passer en mode Édition',
        public: 'Voir la version publique'
      },
      mode: {
        edit: 'Édition',
        public: 'Public'
      },
      title: 'Vous êtes actuellement en mode'
    }
  },
  breadcrumbs: {
    home: 'Accueil',
    label: "Fil d'Ariane"
  },
  button: {
    add: 'Ajouter',
    apply: 'Appliquer',
    cancel: 'Annuler',
    close: 'Fermer',
    delete: 'Supprimer',
    reset: 'Réinitialiser',
    resetAll: 'Tout réinitialiser',
    save: 'Enregistrer',
    send: 'Envoyer',
    update: 'Modifier',
    validate: 'Valider'
  },
  carousel: {
    actions: {
      nextResults: 'Afficher les résultats suivants',
      previousResults: 'Afficher les résultats précédents'
    },
    showAll: 'Tout voir'
  },
  cities: {
    activityNumber: '{0} activités',
    subtitle: 'Découvrez toutes les activités près de chez vous !',
    title: 'Des activités dans toute la France'
  },
  ckEditor: {
    loading: 'Chargement...'
  },
  date: {
    onlyBeginDate: 'Débute le {0}',
    noDate: {
      with2Times: 'De {0} à {1}'
    },
    SameDay: {
      with2Times: 'Le {0}, de {1} à {2}',
      withEndTime: 'Le {0}, jusqu’à {1}',
      withNoTime: 'Le {0}',
      withStartTime: 'Le {0}, à partir de {1}'
    },
    with2Dates: {
      with2Times: 'Du {0} au {1}, de {2} à {3}',
      withEndTime: 'Du {0} au {1}, jusqu’à {2}',
      withNoTime: 'Du {0} au {1}',
      withStartTime: 'Du {0} au {1}, à partir de {2}'
    }
  },
  departments: {
    ain: {
      at: "dans l'",
      from: "de l'"
    },
    aisne: {
      at: "dans l'",
      from: "de l'"
    },
    allier: {
      at: "dans l'",
      from: "de l'"
    },
    'alpes-de-haute-provence': {
      at: 'dans les ',
      from: 'des '
    },
    'alpes-maritimes': {
      at: 'dans les ',
      from: 'des '
    },
    ardeche: {
      at: 'en ',
      from: "de l'"
    },
    ardennes: {
      at: 'dans les ',
      from: 'des '
    },
    ariege: {
      at: 'en ',
      from: "de l'"
    },
    aube: {
      at: "dans l'",
      from: "de l'"
    },
    aude: {
      at: "dans l'",
      from: "de l'"
    },
    aveyron: {
      at: 'en ',
      from: "de l'"
    },
    'bas-rhin': {
      at: 'dans le ',
      from: 'du '
    },
    'bouches-du-rhone': {
      at: 'dans les ',
      from: 'des '
    },
    calvados: {
      at: 'dans le ',
      from: 'du '
    },
    cantal: {
      at: 'dans le ',
      from: 'du '
    },
    charente: {
      at: 'en ',
      from: 'de '
    },
    'charente-maritime': {
      at: 'en ',
      from: 'de '
    },
    cher: {
      at: 'dans le ',
      from: 'du '
    },
    correze: {
      at: 'en ',
      from: 'de '
    },
    'corse-du-sud': {
      at: 'en ',
      from: 'de '
    },
    'cote-d---or': {
      at: 'en ',
      from: 'de '
    },
    'cotes-d---armor': {
      at: 'dans les ',
      from: 'des '
    },
    creuse: {
      at: 'dans la ',
      from: 'de la '
    },
    'deux-sevres': {
      at: 'dans les ',
      from: 'des '
    },
    dordogne: {
      at: 'en ',
      from: 'de '
    },
    doubs: {
      at: 'dans le ',
      from: 'du '
    },
    drome: {
      at: 'dans la ',
      from: 'de la '
    },
    essonne: {
      at: 'en ',
      from: "de l'"
    },
    eure: {
      at: "dans l'",
      from: "de l'"
    },
    'eure-et-loir': {
      at: 'en ',
      from: "d'"
    },
    finistere: {
      at: 'dans le ',
      from: 'du '
    },
    gard: {
      at: 'dans le ',
      from: 'du '
    },
    gers: {
      at: 'dans le ',
      from: 'du '
    },
    gironde: {
      at: 'en ',
      from: 'de '
    },
    guadeloupe: {
      at: 'en ',
      from: 'de '
    },
    guyane: {
      at: 'en ',
      from: 'de '
    },
    'haut-rhin': {
      at: 'dans le ',
      from: 'du '
    },
    'haute-corse': {
      at: 'en ',
      from: 'de '
    },
    'haute-garonne': {
      at: 'en ',
      from: 'de '
    },
    'haute-loire': {
      at: 'en ',
      from: 'de '
    },
    'haute-marne': {
      at: 'en ',
      from: 'de '
    },
    'haute-saone': {
      at: 'en ',
      from: 'de '
    },
    'haute-savoie': {
      at: 'en ',
      from: 'de '
    },
    'haute-vienne': {
      at: 'en ',
      from: 'de '
    },
    'hautes-alpes': {
      at: 'dans les ',
      from: 'des '
    },
    'hautes-pyrenees': {
      at: 'dans les ',
      from: 'des '
    },
    'hauts-de-seine': {
      at: 'dans les ',
      from: 'des '
    },
    herault: {
      at: "dans l'",
      from: "de l'"
    },
    'ille-et-vilaine': {
      at: 'en ',
      from: "d'"
    },
    indre: {
      at: "dans l'",
      from: "de l'"
    },
    'indre-et-loire': {
      at: 'en ',
      from: "de l'"
    },
    isere: {
      at: 'en ',
      from: "de l'"
    },
    jura: {
      at: 'dans le ',
      from: 'du '
    },
    'la--reunion': {
      at: 'à ',
      from: 'de '
    },
    landes: {
      at: 'dans les ',
      from: 'des '
    },
    'loir-et-cher': {
      at: 'dans le ',
      from: 'du '
    },
    loire: {
      at: 'dans la ',
      from: 'de la '
    },
    'loire-atlantique': {
      at: 'en ',
      from: 'de '
    },
    loiret: {
      at: 'dans le ',
      from: 'du '
    },
    lot: {
      at: 'dans le ',
      from: 'du '
    },
    'lot-et-garonne': {
      at: 'dans le ',
      from: 'du '
    },
    lozere: {
      at: 'en ',
      from: 'de '
    },
    'maine-et-loire': {
      at: 'en ',
      from: 'du '
    },
    manche: {
      at: 'dans la ',
      from: 'de la '
    },
    marne: {
      at: 'dans la ',
      from: 'de la '
    },
    martinique: {
      at: 'en ',
      from: 'de '
    },
    mayenne: {
      at: 'en ',
      from: 'de '
    },
    mayotte: {
      at: 'à ',
      from: 'de '
    },
    'meurthe-et-moselle': {
      at: 'en ',
      from: 'de '
    },
    meuse: {
      at: 'dans la ',
      from: 'de la '
    },
    morbihan: {
      at: 'dans le ',
      from: 'du '
    },
    moselle: {
      at: 'en ',
      from: 'de '
    },
    nievre: {
      at: 'dans la ',
      from: 'de la '
    },
    nord: {
      at: 'dans le ',
      from: 'du '
    },
    'nouvelle-caledonie': {
      at: 'en ',
      from: 'de '
    },
    'wallis-et-futuna': {
      at: 'à ',
      from: 'de '
    },
    'saint-pierre-et-miquelon': {
      at: 'à ',
      from: 'de '
    },
    oise: {
      at: "dans l'",
      from: "de l'"
    },
    orne: {
      at: "dans l'",
      from: "de l'"
    },
    paris: {
      at: 'à ',
      from: 'de '
    },
    'pas-de-calais': {
      at: 'dans le ',
      from: 'du '
    },
    'puy-de-dome': {
      at: 'dans le ',
      from: 'du '
    },
    'pyrenees-atlantiques': {
      at: 'dans les ',
      from: 'des '
    },
    'pyrenees-orientales': {
      at: 'dans les ',
      from: 'des '
    },
    rhone: {
      at: 'dans le ',
      from: 'du '
    },
    'saone-et-loire': {
      at: 'en ',
      from: 'de '
    },
    sarthe: {
      at: 'dans la ',
      from: 'de la '
    },
    savoie: {
      at: 'en ',
      from: 'de '
    },
    'seine-et-marne': {
      at: 'en ',
      from: 'de '
    },
    'seine-maritime': {
      at: 'en ',
      from: 'de '
    },
    'seine-saint-denis': {
      at: 'en ',
      from: 'de '
    },
    somme: {
      at: 'dans la ',
      from: 'de la '
    },
    tarn: {
      at: 'dans le ',
      from: 'du '
    },
    'tarn-et-garonne': {
      at: 'en ',
      from: 'du '
    },
    'territoire--de--belfort': {
      at: 'dans le ',
      from: 'du '
    },
    'val-d---oise': {
      at: 'dans le ',
      from: 'du '
    },
    'val-de-marne': {
      at: 'dans le ',
      from: 'du '
    },
    var: {
      at: 'dans le ',
      from: 'du '
    },
    vaucluse: {
      at: 'dans le ',
      from: 'du '
    },
    vendee: {
      at: 'en ',
      from: 'de '
    },
    vienne: {
      at: 'dans la ',
      from: 'de la '
    },
    vosges: {
      at: 'dans les ',
      from: 'des '
    },
    yonne: {
      at: "dans l'",
      from: "de l'"
    },
    yvelines: {
      at: 'dans les ',
      from: 'des '
    }
  },
  editor: {
    paragraph: 'Paragraphe',
    heading1: 'Titre 1',
    heading2: 'Titre 2'
  },
  error: {
    code: {
      400: {
        details:
          'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
        message: 'La requête est invalide.',
        title: 'Toutes nos excuses !'
      },
      401: {
        details:
          'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
        message: 'La requête est invalide.',
        title: 'Toutes nos excuses !'
      },
      403: {
        details:
          'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
        message:
          "Vous n'avez pas les droits nécessaire pour accéder à cette page.",
        title: 'Toutes nos excuses !'
      },
      404: {
        details:
          "Désolé, la page à laquelle vous voulez accéder ne peut pas être trouvée. Le lien est probablement cassé ou bien la page n'existe plus.",
        message: 'Le lien est cassé ou la page a été supprimée.',
        title: 'Page introuvable'
      },
      408: {
        details:
          "L'opération semble être en conflit, veuillez ré-essayer ultérieurement.",
        message: 'Notre serveur a renvoyé une erreur.',
        title: 'Toutes nos excuses !'
      },
      409: {
        details:
          "L'opération semble être en conflit, veuillez ré-essayer ultérieurement.",
        message: 'Notre serveur a renvoyé une erreur.',
        title: 'Toutes nos excuses !'
      },
      413: {
        details:
          'Une erreur est survenue lors du transfert de votre fichier vers notre serveur.',
        message: 'Le fichier est trop volumineux.',
        title: 'Toutes nos excuses !'
      },
      415: {
        details:
          'Une erreur est survenue lors du transfert de votre fichier vers notre serveur.',
        message: "Le fichier n'a pas le bon format.",
        title: 'Toutes nos excuses !'
      },
      500: {
        details:
          'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
        message: 'Notre serveur a renvoyé une erreur.',
        title: 'Toutes nos excuses !'
      },
      503: {
        details:
          'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
        message: 'Le service est inaccessible.',
        title: 'Toutes nos excuses !'
      },
      undefined: {
        title: 'Oof!',
        message: 'Erreur inconnue.'
      }
    },
    form: {
      details:
        'Nous avons dû recevoir une alerte grâce à vous et nous sommes en train de résoudre le problème !',
      message: 'Il y a des erreurs dans le formulaire.',
      title: 'Attention'
    },
    genericError: 'Une erreur est survenue.',
    page: {
      back: 'Retour',
      backToHome: "Revenir à l'accueil",
      redirection_1: 'Vous pouvez nous contacter',
      redirection_2: 'ici',
      redirection_3: 'au sujet de ce problème.',
      statusCode: "Code d'erreur : {0}"
    },
    unknown: {
      details:
        'Nous avons dû recevoir une alerte grâce à vous, et nous sommes en train de résoudre le problème !',
      message: 'Cette erreur nous est inconnue.',
      title: 'Toutes nos excuses !'
    },
    upload:
      'Une erreur est survenue lors du transfert de votre fichier vers notre serveur.',
    uploadedFileTooLarge: 'Le fichier est trop volumineux.',
    uploadedFileWrongFormat: "Le fichier n'a pas le bon format"
  },
  filter: {
    search: 'Recherche',
    activity_type: "Types d'activité",
    categories: 'Catégories',
    ha_tags: 'Tag Helloasso',
    date: 'Date',
    location: 'Localisation',
    price: 'Prix',
    selected: 'Sélectionné.e.s',
    partners: 'Partenaires',
    allActivityTypes: "Tous les types d'activité",
    allCategories: 'Toutes les catégories',
    dateRange: {
      [DateOptions.TODAY]: "Aujourd'hui",
      [DateOptions.TOMORROW]: 'Demain',
      [DateOptions.NEXT_WEEK_END]: 'Ce week-end',
      [DateOptions.CURRENT_WEEK]: 'Cette semaine',
      [DateOptions.NEXT_WEEK]: 'La semaine prochaine',
      [DateOptions.CURRENT_MONTH]: 'Ce mois-ci',
      [DateOptions.NEXT_MONTH]: 'Le mois prochain'
    },
    filters: 'Filtres',
    priceAll: 'Tous les prix',
    priceFree: 'Gratuit',
    priceFrom: 'À partir de {0}',
    priceFromTo: 'De {0} à {1}',
    resetActivityType: "Réinitialiser le type d'activité",
    resetCategory: 'Réinitialiser la catégorie',
    resetHaTags: 'Réinitialiser le tag',
    resetDate: 'Réinitialiser la date',
    resetLocation: 'Réinitialiser la localisation',
    resetPrice: 'Réinitialiser le prix'
  },
  footer: {
    title: {
      start: 'Ensemble',
      end: ' on va plus loin'
    },
    cta: 'Inscrire mon association',
    alternativeCta: 'En savoir plus',
    logo: 'Accueil HelloAsso',
    features: {
      alternativeText:
        'Grâce à un modèle économique reposant uniquement sur la contribution volontaire de chacun et chacune',
      alternativeTitle: 'Modèle alternatif et solidaire',
      universalText:
        'Une solution accessible au plus grand nombre, simple à utiliser.',
      universalTitle: 'Pour tout le monde',
      humanText:
        'Derrière les lignes de codes, il y a toute une équipe engagée auprès de chaque utilisateur et utilisatrice.',
      humanTitle: 'Derrière, des humains'
    },
    link: {
      contact: 'Contact',
      contactUs: 'Contactez-nous',
      press: 'Presse',
      newsletter: 'Newsletter',
      discoverHA: 'Découvrez HelloAsso',
      jobs: 'Jobs',
      whoAreUs: 'Qui sommes-nous ?',
      manifest: 'Le Manifeste',
      commitments: 'Nos engagements',
      help: 'Aide & Ressources',
      resources: 'Webinaires & Guides',
      faq: 'FAQ',
      helpCenter: "Centre d'aide",
      associationsGuide: 'Guide des associations',
      downloadOurApp: 'Télécharger notre application',
      legalInfos: 'Informations légales',
      userCGU: 'CGU Utilisateurs',
      associationCGU: 'CGU Associations',
      legalNotice: 'Mentions légales',
      privacy: 'Confidentialité',
      activitiesReport: 'Rapports d’activités',
      cookiesManagement: 'Gestion des cookies',
      activities: 'Activités',
      workshopsAndCourses: 'Ateliers et stages',
      competitionsAndTournaments: 'Compétitions et tournois',
      courses: 'Cours',
      running: 'Courses',
      festivals: 'Festivals',
      projectFinancing: 'Financements de projet',
      evenings: 'Soirées',
      showsAndConcerts: 'Spectacles et concerts',
      raffle: 'Tombola',
      blog: 'Blog',
      assoGroup: 'Le groupe des assos',
      status: 'HelloAsso Status',
    },
    mentions: {
      copyright: 'Tous droits réservés',
      fpfMember: 'Membre du FPF - Financement Participatif France',
      regulatedPlatform: 'Plateforme régulée',
      reassurance:
        'La plateforme de paiement des associations pour rechercher des financements : adhésion, dons, crowdfunding, boutique en ligne, billetterie... Lancez-vous !',
      lemonway_1:
        "HelloAsso est un établissement de paiement agréé par l'ACPR en France le 08/06/2023 sous le numéro 17678Q.",
      lemonway_2:
        "Notre plateforme de paiement en ligne est également agréée ESUS (Entreprise Solidaire d'Utilité Sociale), ainsi qu'Intermédiaire en Financement Participatif auprès de l'ORIAS sous le numéro 17002233.",
      lemonway_3:
        "HelloAsso est partenaire de Lemonway, établissement de paiement agréé par l'ACPR en France le 24/12/2012 sous le numéro 16568J."
    },
    aboutHa:
      'Quels que soient nos goûts ou les causes que l’on défend, nous croyons que les partager ensemble est essentiel. Les associations nous donnent cette opportunité chaque jour. Grâce à notre plateforme, nous permettons de trouver une activité à faire ensemble, et nous aidons celles et ceux qui les proposent à les organiser au mieux.',
    alternativeAboutHa:
      "HelloAsso est une entreprise solidaire d'utilité sociale. Nous fournissons nos technologies de paiement gratuitement, sans frais ni commissions, à plus de 200 000 associations françaises. Les contributions volontaires que nous laissent les internautes sont notre unique source de revenus. Merci pour votre soutien !"
  },
  howItWorks: {
    discoverTogether: 'Participez, apprenez, découvrez, ensemble',
    findMyPayments: 'Retrouver mes paiements HelloAsso',
    findNewActivities: 'Trouver une activité associative',
    features: {
      organization: {
        launchYourProjects_1: 'Lancez vos projets',
        launchYourProjects_2: '(adhésions, billetteries...).',
        getPayments_1: 'Recevez des paiements',
        getPayments_2: 'en ligne.',
        getHelp_1: 'Bénéficiez d’un accompagnement',
        getHelp_2: 'personnalisé.'
      },
      user: {
        findOrganizations_1: 'Découvrez les associations',
        findOrganizations_2: 'qui agissent près de chez vous.',
        findActivites_1: 'Trouvez des activités et des événements',
        findActivites_2: 'qui vous plaisent.',
        toParticipate_1: 'Pour participer,',
        toParticipate_2: 'payez en ligne en toute sécurité.'
      }
    },
    handleActivities: 'Gérez, diffusez et financez vos activités',
    organizations: 'Associations, clubs et fédérations',
    signIn: 'Me connecter à mon compte association',
    signUp: 'Inscrire mon association gratuitement',
    title: 'Comment ça marche ?',
    users: 'Particuliers'
  },
  inviteOrgToSignUp: {
    inviteByEmail: 'Inviter par email',
    link: 'Vous êtes une association ? Inscrivez-vous pour référencer vos activités.',
    title:
      'L’association que vous recherchez n’est pas sur HelloAsso ?'
  },
  list: {
    type: {
      event: {
        name: {
          competitions: 'Compétitions et tournois',
          concerts: 'Concerts',
          conferences: 'Conférences et débats',
          exhibitions: 'Salons, foires et expositions',
          festivals: 'Festivals',
          lessons: 'Cours, leçons et entraînements',
          parties: 'Soirées',
          races: 'Courses',
          shows: 'Spectacles',
          tombolas: 'Tombolas',
          visits: 'Sorties, balades et visites',
          workshops: 'Ateliers et stages'
        },
        title: 'Nos événements par type'
      }
    }
  },
  meta: {
    title: {
      createMyWidget: 'Créer mon widget',
      search:
        "À la recherche d'une activité ou d'un projet associatif à soutenir ?"
    },
    description: {
      createMyWidget:
        'Personnalisez ici vos filtres de recherche pour intégrer des associations, activités et projets HelloAsso directement sur votre site !',
      search:
        "Cours de sports, sorties culturelles, balade à vélo, tournois de tennis, conférence sur le climat... HelloAsso est la plateforme qui vous permet de trouver l'activité à faire, le projet à soutenir, l'association avec qui s'engager et bien plus encore."
    }
  },
  microdata: {
    breadcrumbListName: 'Les associations et leurs activités'
  },
  modal: {
    filtersAll: {
      title: 'Filtres'
    },
    login: {
      connect: 'Se connecter',
      email: 'Email',
      errors: {
        login: {
          details: 'La connexion a été refusée par le serveur.',
          message:
            'Veuillez vérifier votre identifiant et votre mot de passe.',
          title: 'Une erreur est survenue'
        }
      },
      forgotPassword: 'Mot de passe oublié ?',
      password: 'Mot de passe',
      title: 'Connexion à HelloAsso',
      firstTimeConnect: 'Première connexion ?'
    },
    newAction: {
      title: 'Quelle campagne voulez-vous lancer ?'
    },
    report: {
      emailInput: {
        error:
          'Votre email doit suivre le format suivant : "jean{\'@\'}exemple.fr".',
        label: 'Votre email',
        placeholder: "Exemple : jean{'@'}exemple.com"
      },
      errorToast: {
        body: "Votre signalement n'a pas pu être envoyé, veuillez réessayer ultérieurement",
        title: "Une erreur s'est produite"
      },
      messageInput: {
        error: 'Veuillez renseigner la raison de votre signalement.',
        label: 'Votre message',
        placeholder: 'La raison de votre signalement.'
      },
      reasonRequired: 'Vous devez sélectionner une raison.',
      reasons: {
        DISLIKE: "Je ne l'aime pas",
        INTELLECTUAL_PROPERTY:
          "Je pense que c'est une utilisation non autorisée de ma propriété intellectuelle",
        OFF_TOPIC: "Je pense qu'elle n'a rien à faire sur HelloAsso",
        SPAM: "C'est du contenu indésirable"
      },
      successToast: {
        body: 'Merci pour votre contribution',
        title: 'Signalement envoyé'
      },
      title: 'Signaler cette page',
      why: 'Pourquoi souhaitez-vous signaler cette page ?'
    },
    searchBy: {
      location: {
        title: 'Modifier la localisation'
      },
      name: {
        title: 'Lancer une recherche'
      }
    },
    upload: {
      choose: 'Choisir mon fichier',
      suggestedSize: 'Taille recommandée',
      update: 'Modifier mon fichier'
    }
  },
  organization: {
    about: {
      title_01: 'Qui',
      title_02: 'sommes-nous ?',
      longDescriptionPlaceholder:
        'Présentez votre association, ajoutez des images et des vidéos...'
    },
    actions: {
      action: {
        OnlineEvent: 'Evenement en ligne',
        bannerAlt: "Bannière de l'action",
        collected: 'collecté | collecté | collectés',
        contributors: 'contributeur | contributeur | contributeurs',
        editLabel: "Éditer l'action {0}",
        goal: "d'objectif",
        members: 'adhérent | adhérent | adhérents',
        on: 'sur',
        share: {
          copyUrl: {
            label: "Copier l'URL"
          },
          email: {
            body: 'Bonjour ! J’ai découvert ce projet sur HelloAsso. Il est organisé par {0} et je pense que ça pourrait vous intéresser. Rendez-vous sur la page {1} pour plus d’infos. À bientôt.',
            label: 'Email'
          },
          facebook: {
            label: 'Facebook'
          },
          linkedin: {
            label: 'LinkedIn'
          },
          twitter: {
            label: 'Twitter',
            text: "L'action {0} pourrait vous intéresser ! {1} {'@'}helloasso"
          }
        },
        shareLabel: 'Partager cette action',
        free: 'Gratuit',
        startingFromTo: 'De {from} à {to}',
        priceFrom: 'À partir de {0}',
        customPrice: 'Montant libre',
        toolAlt: 'Outil',
        group: {
          crowdfunding: {
            cta: "Je m'engage",
            label: 'Je soutiens {0}',
            name: 'Campagne de crowdfunding'
          },
          donation: {
            cta: 'Je donne',
            label: 'Je fais un don à {1}',
            name: 'Formulaire de don'
          },
          event: {
            cta: 'Je participe',
            label: 'Je participe à {0}',
            name: 'Billetterie'
          },
          shop: {
            cta: 'Je participe',
            label: 'Je participe à {0}',
            name: 'Boutique en ligne'
          },
          membership: {
            cta: "J'adhère",
            label: "J'adhère à {1}",
            name: "Campagne d'adhésion"
          },
          paymentform: {
            cta: 'Je participe',
            label: 'Je participe à {0}',
            name: 'Formulaire de paiement'
          }
        }
      },
      group: {
        crowdfunding: {
          showAll:
            'Voir tous les projets | Afficher moins de projets',
          title: 'Soutenir nos projets'
        },
        donation: {
          showAll:
            'Voir tous les formulaires | Voir moins de formulaires',
          title: 'Nous faire un don'
        },
        event: {
          showAll:
            "Voir tous les événements | Voir moins d'événements",
          title: 'Participer à nos événements'
        },
        membership: {
          showAll:
            "Voir toutes les activités | Voir moins d'activités",
          title: 'Adhérer à nos activités'
        },
        paymentform: {
          showAll: 'Voir tous les services | Voir moins de services',
          title: 'Découvrir nos services'
        },
        shop: {
          showAll: 'Voir tous nos produits | Voir moins de produits',
          title: 'Achetez nos produits'
        }
      },
      newAction: {
        cta: 'Créer une nouvelle campagne',
        title:
          'Lancez une nouvelle campagne et faites-le savoir dès maintenant à votre communauté.',
        typologies: {
          crowdfunding: 'Un financement participatif',
          donation: 'Un formulaire de don',
          event: 'Une billetterie en ligne',
          membership: "Un formulaire d'adhésion",
          paymentform: 'Une vente de produit ou de service',
          shop: 'Une boutique en ligne'
        }
      },
      noActions: {
        title_01:
          'Nous sommes actuellement en train de mettre en place nos prochaines initiatives.',
        title_02: 'Revenez bientôt pour ne rien manquer !',
        titleFirstAction_01:
          'C’est bien vide par ici, pourquoi n’en profiterez-vous pas pour',
        titleFirstAction_02: 'lancer votre première campagne ?'
      },
      title_01: 'Nos actions',
      title_02: 'en cours'
    },
    backToTopLabel: 'Retour en haut de la page',
    carousel: {
      addImage: 'Ajouter une image',
      addVideo: 'Ajouter une vidéo',
      legendLabel: 'Légende de votre image ou vidéo',
      legendPlaceholder: 'Légende',
      prevLabel: 'Image précédente',
      nextLabel: 'Image suivante',
      title_01: "L'organisme",
      title_02: 'en images',
      uploadImage: 'Importer',
      uploadImageLabel: 'une nouvelle image',
      videoUrl: 'URL de votre vidéo',
      add: {
        image: {
          title: 'Super ! 🎉',
          body: 'Votre image a bien été ajoutée.'
        },
        video: {
          title: 'Super ! 🎉',
          body: 'Votre vidéo a bien été ajoutée.'
        }
      },
      delete: {
        image: {
          title: 'Ok ! 👍',
          body: "L'image a bien été supprimée."
        },
        video: {
          title: 'Ok ! 👍',
          body: 'La vidéo a bien été supprimée.'
        }
      }
    },
    comments: {
      title_01: 'Vous aimez notre organisme ?',
      title_02: 'Dites-le nous !'
    },
    contact: {
      infos: {
        address: {
          city: 'Ville',
          label: 'Adresse',
          placeholder: '1 rue Victor Hugo 33000 Bordeaux',
          placeholderAddress: '1 rue Victor Hugo',
          placeholderCity: 'Bordeaux',
          placeholderZipcode: '33000',
          street: 'Numéro et nom de rue',
          switchToAuto: "Je veux que l'on me propose une adresse",
          switchToManual: 'Je veux saisir mon adresse manuellement',
          zipCode: 'Code postal'
        },
        contactUs: "Contacter l'organisme",
        coordinates: {
          customCoordinates:
            'Quand vous saisissez manuellement votre adresse le positionnement sur la carte peut être imprécis. Vous pouvez modifier les coordonnées latitude et longitude pour vous afficher au point exact souhaité.',
          displayCoordinates:
            'Afficher les coordonnées sur la page publique de mon organisme',
          latitude: {
            label: 'Latitude',
            placeholder: '44.837788'
          },
          longitude: {
            label: 'Longitude',
            placeholder: '-0.579180'
          }
        },
        error: {
          unknownAddress: {
            title: "L'adresse saisie n'est pas reconnue",
            body: 'Veuillez réessayer avec une adresse proche ou un autre code postal.'
          }
        },
        email: {
          label: 'Email',
          placeholder: "marie.durand{'@'}gmail.com",
          showEmail: "Afficher l'email"
        },
        phone: {
          label: 'Téléphone',
          placeholder: '06XXXXXXXX',
          showPhoneNumber: 'Afficher le numéro'
        },
        title: 'Coordonnées'
      },
      openSettings: 'Afficher les options',
      socials: {
        title: 'Suivez-nous !',
        socialNetwork: {
          facebookPage: {
            labelEdit: "Modifier l'URL de la page Facebook",
            labelIcon: 'Aller sur notre page Facebook',
            placeholder: 'Facebook'
          },
          instagramPage: {
            labelEdit: "Modifier l'URL de la page Instagram",
            labelIcon: 'Aller sur notre page Instagram',
            placeholder: 'Instagram'
          },
          twitterPage: {
            labelEdit: "Modifier l'URL de la page Twitter",
            labelIcon: 'Aller sur notre page Twitter',
            placeholder: 'Twitter'
          },
          webSite: {
            labelEdit: "Modifier l'URL du site officiel",
            labelIcon: 'Aller sur notre site officiel',
            placeholder: 'Site officiel'
          },
          youtubePage: {
            labelEdit: "Modifier l'URL de la page YouTube",
            labelIcon: 'Aller sur notre page YouTube',
            placeholder: 'YouTube'
          },
          mailTo: {
            body: '{0}. Rendez-vous sur: {1}'
          }
        }
      },
      title_01: 'Où',
      title_02: 'nous trouver'
    },
    headline: {
      banner: {
        delete: 'Supprimer la bannière de mon organisme',
        update: 'Modifier la bannière',
        updateLabel: 'de mon organisme'
      },
      categoryLabel: 'Catégorie de votre organisme',
      logo: {
        delete: 'Supprimer le logo de mon organisme',
        upload: 'Modifier le logo de mon organisme'
      },
      shortDescriptionLabel: 'Description brève de votre organisme',
      shortDescriptionPlaceholder: 'En quelques mots...'
    },
    highlight: {
      subtitle: 'À la une'
    },
    navigation: {
      actions: 'Nos actions',
      ariaLabel: 'Navigation de la page',
      highlight: 'À la une',
      event: 'Nos événements',
      membership: 'Adhérer',
      donation: 'Faire un don',
      crowdfunding: 'Nos projets',
      shop: 'Nos produits',
      paymentform: 'Nos services',
      about: 'Qui sommes-nous ?',
      contact: 'Où nous trouver ?'
    },
    news: {
      about:
        'Pour ne rien rater de nos actions associatives, laissez-nous votre adresse email et vous serez informé dès que nous lançons une nouvelle action via HelloAsso !',
      data: 'Votre adresse mail sera uniquement utilisée pour que vous receviez nos nouvelles campagnes HelloAsso. Elle ne sera jamais ô grand jamais communiquée à l’extérieur et un lien de désabonnement sera présent dans tous les emails reçu. Pour en savoir plus sur la gestion de vos données et vos droits, c’est ici.',
      inputPlaceholder: "monadressemail{'@'}email.com",
      sendNews: 'Envoyez-moi vos news !',
      title_01: 'Restez',
      title_02: 'informés'
    },
    pageTitle: "Éditer l'organisme",
    report: {
      reportPage: 'Signaler la page'
    },
    share: {
      cta: 'Partager la page',
      networks: {
        copyUrl: {
          label: "Copier l'URL"
        },
        genericShare: {
          label: 'Autre application'
        },
        email: {
          body: 'Bonjour ! Je vous partage la page de cet organisme que j’ai découvert sur Helloasso. Je pense que ses activités pourraient vous intéresser ! Rendez-vous sur la page {0} pour plus d’infos. À bientôt.',
          label: 'Email',
          subject: 'Découvre l’organisme {0}.'
        },
        facebook: {
          label: 'Facebook'
        },
        linkedin: {
          label: 'LinkedIn'
        },
        twitter: {
          label: 'Twitter',
          text: "J'ai découvert l'organisme {0} sur HelloAsso, ça pourrait vous intéresser ! {1} {'@'}helloasso"
        }
      },
      subtitle: 'Aidez-nous à le faire connaître !',
      title: 'Vous aimez notre organisme ?'
    },
    volunteer: {
      title: 'Devenez bénévole',
      description:
        'Trouvez la mission de bénévolat qui vous correspond près de chez vous ou à distance parmi les milliers disponibles.',
      button: "Je m'engage",
      by: 'par'
    }
  },
  organizationsTypes: {
    Association1901: 'Association Loi 1901',
    Association1901Rup: "Association Loi 1901 d'interêt publique",
    Association1901Rig: "Association Loi 1901 d'interêt général",
    FondationSousEgide: 'Fondation sous Egide',
    FondationRup: "Fondation d'interêt publique",
    FondDotation: 'Fond de Dotation',
    FondationScientifique: 'Fondation Scientifique',
    FondationPartenariale: 'Fondation Partenariale',
    FondationUniversitaire: 'Fondation Universitaire',
    FondationHospitaliere: 'Fondation Hospitaliere',
    Association1905: 'Association Loi 1905',
    Association1905Rup: "Association Loi 1905 d'interêt publique",
    Entreprise: 'Entreprise',
    Cooperative: 'Coopérative',
    Etablissement: 'Établissement',
    Association1908: 'Association Loi 1908',
    Association1908Rig: "Association Loi 1908 d'interêt général",
    Association1908Rup: "Association Loi 1908 d'interêt publique"
  },
  range: {
    free: 'Gratuit',
    maxPrice: 'Prix maximum',
    minPrice: 'Prix minimum'
  },
  toActivityTypes: {
    atelier: 'aux ateliers',
    benevolat: 'au bénévolat',
    competition: 'aux compétitions',
    concert: 'aux concerts',
    conference: 'aux conférences',
    cours: 'aux cours',
    culte: 'aux cultes',
    exposition: 'aux expositions',
    festival: 'aux festivals',
    fete: 'aux fêtes',
    financement: 'aux financements de projets',
    formation: 'aux formations',
    jeu: 'aux jeux',
    sejour: 'aux séjours',
    sortie: 'aux sorties',
    spectacle: 'aux spectacles'
  },
  regions: {
    'auvergne-rhone-alpes': {
      at: 'en ',
      from: "d'"
    },
    'bourgogne-franche-comte': {
      at: 'en ',
      from: 'de '
    },
    bretagne: {
      at: 'en ',
      from: 'de '
    },
    'centre-val--de--loire': {
      at: 'en ',
      from: 'du '
    },
    corse: {
      at: 'en ',
      from: 'de '
    },
    'grand--est': {
      at: 'dans le ',
      from: 'du '
    },
    guadeloupe: {
      at: 'en ',
      from: 'de '
    },
    guyane: {
      at: 'en ',
      from: 'de '
    },
    'hauts-de-france': {
      at: 'dans les ',
      from: 'des '
    },
    'ile-de-france': {
      at: 'en ',
      from: "d'"
    },
    'la--reunion': {
      at: 'en ',
      from: 'de '
    },
    martinique: {
      at: 'en ',
      from: 'de '
    },
    mayotte: {
      at: 'à ',
      from: 'de '
    },
    normandie: {
      at: 'en ',
      from: 'de '
    },
    'nouvelle-caledonie': {
      at: 'en ',
      from: 'de '
    },
    'wallis-et-futuna': {
      at: 'à ',
      from: 'de '
    },
    'saint-pierre-et-miquelon': {
      at: 'à ',
      from: 'de '
    },
    'nouvelle-aquitaine': {
      at: 'en ',
      from: 'de '
    },
    occitanie: {
      at: 'en ',
      from: "d'"
    },
    'pays--de--la--loire': {
      at: 'au ',
      from: 'du '
    },
    'polynesie--francaise': {
      at: 'en ',
      from: 'de '
    },
    'provence-alpes-cote--d---azur': {
      at: 'en ',
      from: 'de '
    },
    'saint--pierre--et--miquelon': {
      at: 'à ',
      from: 'de '
    }
  },
  search: {
    allForms: 'Tous les événements',
    allOrganizations: 'Toutes les Associations',
    emptyState: {
      resultType: {
        [Tabs.ALL]: 'de résultats',
        [Tabs.ORGANIZATIONS]: "d'associations",
        [Tabs.ACTIVITIES]: "d'activités",
        [Tabs.PROJECTS]: 'de projets',
        categories: 'de catégories'
      },
      titleBeginning:
        'Oh non, il semblerait qu’il n’y ait pas encore {0} pour ',
      titleDefault: 'votre recherche',
      titleEnd: 'sur HelloAsso 😔',
      subtitle_01:
        'Pas de panique, il y a plein d’autres activités disponibles ! Continuez d’explorer celles proches de chez vous en effectuant',
      subtitle_02: 'une nouvelle recherche',
      subtitle_03: 'ou bien réinitialisez vos filtres.'
    },
    map: {
      compress: 'Afficher la liste',
      expand: 'Agrandir',
      hideMap: 'Cacher la carte',
      showMap: 'Afficher la carte',
      showMapFull: 'Afficher la carte en plein écran',
      errorWebGlToast: {
        body: 'Votre navigateur ne peut pas afficher la carte. Merci de réessayer sur une version plus récente.',
        title: "Une erreur s'est produite"
      }
    },
    resetFilters: 'Réinitialiser les filtres',
    results: '{0} résultat | {0} résultats',
    someForms: 'Événements',
    someOrganizations: 'Associations',
    tabs: {
      [TABS.ALL]: 'tout',
      [TABS.ORGANIZATIONS]: 'associations',
      [TABS.ACTIVITIES]: 'activités',
      [TABS.PROJECTS]: 'projets',
      organizations: 'associations',
      activities: 'activités',
      projects: 'projets'
    },
    title: 'Résultats pour : ',
    titleDefault: 'Explorer les activités associatives'
  },
  searchBy: {
    noResults: 'Aucun résultat 🌵',
    placeholderLocation: 'Une ville, un code postal...',
    placeholderName: 'Une association, une activité...',
    placeholderPartner: 'Un partenaire HelloAsso...',
    recent: 'Récentes',
    results: 'Résultats',
    usePosition: 'Utiliser ma position actuelle',
    type: {
      municipality: 'Commune',
      housenumber: 'Numéro',
      locality: 'Lieu-dit',
      street: 'Rue'
    }
  },
  share: {
    buttonLabel: {
      copyUrl: "Copier l'URL dans le presse-papiers",
      genericShare: 'Partager dans une autre application'
    },
    prefilledText: {
      email: {
        subject:
          'Je vous invite à inscrire votre organisme sur HelloAsso.',
        body: "Bonjour! Saviez-vous que le site helloasso.com référence les activités associatives dans toute la France et les DOM-TOM? Je vous recommande d'inscrire votre organisme pour lui faire bénéficier de cette visibilité en ligne. Rendez-vous sur https://www.helloasso.com/associations/ pour découvrir la plateforme gratuite dédiée aux associations."
      },
      linkedin: 'Inscrivez-vous dès maintenant sur HelloAsso ! {0}',
      twitter:
        "Inscrivez-vous dès maintenant sur HelloAsso ! {0} {'@'}helloasso"
    },
    sharePage: 'Partager cette page'
  },
  signUp: {
    subtitle_01: 'Gérez, diffusez et financez',
    subtitle_02: 'gratuitement',
    subtitle_03: 'vos activités.',
    button: 'Inscrire mon association'
  },
  sitemap: {
    activities: {
      byActivityType: "Par type d'activité :"
    },
    categories: {
      inThisCategory: 'Dans la catégorie :'
    },
    geo: {
      otherCities: 'Dans les villes alentours :',
      otherDepartments: 'Dans les départements alentours :'
    },
    subtitle: 'Explorez les activités associatives près de chez vous',
    title: 'Toutes les activités liées'
  },
  thumbnail: {
    activityNumber: '{0} activité | {0} activités',
    by: 'Par',
    collected: 'collectés',
    free: 'Gratuit',
    funded: 'Financé à',
    over: 'Terminé',
    madeBy: 'Organisé par',
    moreThan: 'Plus de {0} activités',
    startingAt: 'À partir de {0}',
    remainingM: 'restant | restants',
    remainingF: 'restante | restantes',
    time: {
      years: 'année | années',
      months: 'mois',
      days: 'jour | jours',
      hours: 'heure | heures',
      minutes: 'minute | minutes'
    },
    activityImageAlternative: '"{0}" par {1}',
    projectImageAlternative: '"{0}" par {1}'
  },
  toast: {
    bannerDelete: {
      title: 'Ok ! 👍',
      body: "La bannière de l'organisme a bien été supprimée."
    },
    categoryUpdate: {
      title: 'Super ! 🎉',
      body: "La catégorie de l'organisme a bien été mise à jour."
    },
    copyUrl: {
      title: 'URL copiée ! 💪',
      body: "Vous pouvez maintenant l'utiliser pour partager la page."
    },
    copyIntegrationCode: {
      title: 'Code copié ! 💪',
      body: "Vous pouvez maintenant coller le code d'intégration dans l'éditeur de votre site web."
    },
    displayCoordinates: {
      title: 'Compris ! 💪',
      bodyTrue:
        "Nous afficherons désormais les coordonnées de l'organisme.",
      bodyFalse:
        "Nous n'afficherons plus les coordonnées de l'organisme."
    },
    infoDelete: {
      title: 'Parfait ! 🎉',
      body: 'Cette information a bien été supprimée.'
    },
    infoUpdate: {
      title: 'Parfait ! 🎉',
      body: 'Vos informations ont bien été mises à jour.',
      bodyAddressManual:
        'Rafraichissez la page pour voir les modifications.'
    },
    logoDelete: {
      title: 'Ok ! 👍',
      body: "Le logo de l'organisme a bien été supprimé."
    },
    longDescriptionUpdate: {
      title: 'Yeah ! 🎉',
      body: 'Votre description longue a bien été mise à jour.'
    },
    sendNews: {
      title: 'Merci de votre soutien ! ❤️',
      body: "Vous recevrez une notification sur cette adresse email à chaque nouvelle action de l'organisme."
    },
    shortDescriptionUpdate: {
      title: 'Nickel ! 🎉',
      body: 'Votre description courte a bien été mise à jour.'
    },
    updateSucess: {
      title: 'Cool ! 🎉',
      body: 'Vos modifications ont bien été enregistrées.'
    },
    redirect: {
      title: 'Vous avez été automatiquement redirigé',
      body: "La page demandée n'est pas disponible, mais celle-ci pourrait vous intéresser."
    }
  },
  tracker: {
    marketing: {
      description:
        'Pour permettre à Helloasso et à ses partenaires de diffuser des publicités correspondant davantage à vos intérêts',
      title: 'Marketing & Publicitaires'
    },
    traffic: {
      description:
        "Pour analyser l'utilisation du site afin de mesurer et d'améliorer les performances",
      title: "Mesure d'audience du site"
    }
  },
  chipsFilter: {
    label: 'Filtrer par'
  },
  oldValidators: {
    alphaDash:
      'Certains caractères spéciaux sont interdits, comme #, \\, ou <.',
    coordinates: 'Les coordonnées ne sont pas au bon format.',
    email:
      "Le format de l'adresse est invalide. Il manque peut-être un {'@'} ou un .",
    notLegendOnly:
      "Pour ajouter une vidéo, n'oubliez pas de remplir une URL ci-dessus !",
    phone: 'Essayez plutôt au format 0544332211 ou +33544332211 !',
    required: 'Ce champ est requis.',
    url: "L'URL devrait commencer par https://, http:// ou www.",
    zipCode: 'Doit être composé de 5 chiffres.',
    file_type: "Le type du fichier n'est pas valide.",
    file_max_size: 'Poids maximum autorisé : {0}.',
    city: 'Saisissez un nom de ville sans chiffres.'
  },
  widget: {
    copyWidgetUrl: "Copier le code d'intégration",
    createMyWidget: 'Créer mon widget',
    iframeTitle: 'Widget HelloAsso',
    name: {
      carousel: 'Carrousel',
      hitlist: 'Liste'
    },
    noResults: 'Pas de résultats',
    typeOfWidget: 'Type de widget',
    urlOfWidget: 'URL du widget'
  },
  faq: {
    abbr: 'FAQ',
    fullWord: 'Foire aux questions'
  },
  homeCategories: {
    forCategory: 'pour la categorie',
    iWant: 'Je veux',
    getMoreInformations: 'En savoir plus'
  },
  homeBrand: {
    logoAlt: "HelloAsso l'engagement tout simplement",
    mapAlt:
      'Présentation de la carte des plus de 300 000 associations partout en France sur tous les sujets',
    assoTitle: 'associations',
    everywhere: 'partout en France sur tous les sujets',
    thanksTo:
      'C’est grâce à <span>vos contributions volontaires !</span>',
    explore:
      "Explorez des opportunités d'engagement unique au sein d'associations locales. Devenez bénévole, découvrez des projets enrichissants, et faites de chaque expérience associative une aventure mémorable",
    knowMore:
      'En savoir plus sur le rôle des contributions volontaire',
    servicesAlt: "Les différents services d'HelloAsso",
    togetherAlt: "Agrément entreprise solidaire d'utilité sociale",
    together: 'Ensemble <span>on va plus loin</span>',
    firstStep:
      'Faire le premier pas vers une association pour y partager des moments ensemble devrait être aussi simple que de dire “Bonjour”.',
    association: {
      title: 'Pour les associations',
      description:
        'La seule plateforme de gestion gratuite et sécurisée'
    },
    people: {
      title: 'Pour les particuliers',
      description:
        '10 millions de personnes qui ont déjà utilisé HelloAsso'
    },
    everyone: {
      title: 'Pour l’intérêt général',
      description:
        "1 milliard d'euros collectés au profit des associations françaises"
    },
    actTogether: {
      title: 'Pour agir ensemble',
      description: 'Et créer une société de liens qui font du bien'
    }
  },
  seoMeshing: {
    title: 'Toutes les associations',
    subtitle:
      'Sport, culture, solidarité, découvrez toutes les associations de votre ville et engagez-vous! En participant à une activité ensemble, en devenant bénévole, en soutenant des projets, vivez une expérience d’engagement qui vous ressemble.'
  },
  homeMission: {
    titleFirstPart: 'La mission',
    titleSecondPart: 'de HelloAsso'
  },
  homeActivities: {
    inputPlaceholder: 'Je choisis ma ville ...',
    alt: 'Saisir ma ville'
  },
  homeHero: {
    play: 'lecture',
    pause: 'pause'
  }
}
